import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackendService } from '../backend.service';
import { RealTimeService } from '../real-time.service';

@Component({
  selector: 'app-locked',
  templateUrl: './locked.component.html',
  styleUrls: ['./locked.component.css']
})
export class LockedComponent implements OnInit, OnDestroy {

  private sub: Subscription;

  constructor(private rs: RealTimeService, private router: Router, private backend: BackendService) { }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.rs.send({ receiver: this.rs.posDisplay, controller : 'pos', pos : this.rs.deviceId,
    name: this.backend.activeUserName,
    action : 'standby'});

    this.sub = this.rs.onMessage.subscribe(data => {
      if (data.action === 'ausweis_read') {
        /* Ausweisdaten anfragen, Session mit User starten */
        if (data.ausweis.ausweisNr === this.backend.state.ausweis || !this.backend.state.ausweis) {
          this.router.navigate(['/pos/user-select']);
        } else {
          console.log('Ungültiger Ausweis: ', data.ausweis.ausweisNr, this.backend.state.ausweis);
        }
      }
    });
  }

}
