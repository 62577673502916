import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KasseInfo } from './api/KasseInfo';
import { BackendService } from './backend.service';
import { RealTimeService } from './real-time.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  title = 'fw-pos';

  constructor(private rs: RealTimeService, private router: Router, private http: HttpClient, private backend: BackendService) {

  }


  ngOnInit() {
    const kasseProvKey = localStorage.getItem('pos_prov_key');
    if (kasseProvKey === null) {
      this.router.navigate(['prov']);
    } else {
      this.backend.activate();
    }
  }
}
