export class Session {
    public id: number;
    public vorname: string;
    public nachname: string;
    public konto: number;
    public spitzname: string;
    public summe: number;
    public artikel: SessionArtikel[] = [];
}

export class SessionArtikel {
    public id: number;
    public bezeichnung: string;
    public menge: number;
    public einzelpreis: number;
}
