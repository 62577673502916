<div class="container">
    <h1>Beleg erfassen</h1>
    <div class="p-1" [hidden]="photoTaken">
        <video #video class="vid" autoplay></video>
     </div>
     <div class="pb-2" [hidden]="photoTaken">
        <button class="btn btn-primary" (click)="capture()">Bild speichern</button>
     </div>
     <div class="p-1" [hidden]="!photoTaken">
        <canvas #canvas class="vid" style="width:30vw; height:30vh;"></canvas>
     </div>
     <div *ngIf="photoTaken">
        <h2>Belegdaten</h2>
        <div class="form-group">
            <label for="inputGrund">Betreff / Grund</label>
            <input type="text" required class="form-control" id="inputGrund" [(ngModel)]="grund" aria-describedby="grundHelp" placeholder="Bitte Betreff eingeben">
            <small id="grundHelp" class="form-text text-muted">Bitte kurz angeben worum es sich bei dem Beleg handelt. (z.B. Essenseinkauf)</small>
        </div>
        <div class="form-group">
            <label for="inputBetrag">Betrag</label>
            <input type="number" step="0.01" min="0" max="1000" required [(ngModel)]="betrag" class="form-control" id="inputBetrag" aria-describedby="betragHelp" placeholder="Bitte Betrag eingeben">
            <small id="betragHelp" class="form-text text-muted">Bitte den Endbetrag angeben, der dir gutgeschrieben werden soll.</small>
        </div>
        <div class="form-group">
            <label for="inputUser">Gutschrift für</label>
            <select required class="form-control" id="inputUser" [(ngModel)]="user" aria-describedby="userHelp" placeholder="Bitte Empfänger wählen">
                <option *ngFor="let u of users" [ngValue]="u.id">{{u.nachname}}, {{u.vorname}}</option>
            </select>
            <small id="userHelp" class="form-text text-muted">Bitte auswählen, wer das Geld vorgelegt hat und gutgeschrieben bekommen soll.</small>
        </div>
        <button class="btn btn-primary" [disabled]="betrag === null || betrag <= 0 || grund === null || grund === ''" (click)="save()">Beleg speichern und Geld anfordern</button>
     </div>
</div>
