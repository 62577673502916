<div *ngIf="view=='start'">
    <div class="container d-flex h-100">
        <div class="row align-self-center w-100">
            <div class="col-6 mx-auto">
                <div class="jumbotron">
                    <h1 class="display-4">Kasse abgeschaltet</h1>
                    <p class="lead">Bitte Kassenmodus wählen und Kasse einschalten</p>
                    <p><select [(ngModel)]="selectedListe">
                            <option *ngFor="let liste of preislisten" [ngValue]="liste.id">{{liste.bezeichnung}}
                            </option>
                        </select> </p>
                    <p><button class="btn btn-success" (click)="start()">Kasse einschalten</button></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="view=='wait'">
    <div class="container d-flex h-100">
        <div class="row align-self-center w-100">
            <div class="col-6 mx-auto">
                <div class="jumbotron">
                    <h1 class="display-4">Dienstausweis scannen</h1>
                    <p class="lead">Bitte Dienstausweis an Kundendisplay scannen oder mit Anmeldedaten einloggen</p>
                    <p>
                        <label for="inputName">Benutzername</label>
                        <input type="text" class="form-control" [(ngModel)]="loginUsername"
                            id="inputName">
                    </p>
                    <p>
                        <label for="inputPassword">Passwort</label>
                        <input type="password" class="form-control" [(ngModel)]="loginPassword" id="inputPassword">
                    </p>

                    <p>
                        <button class="btn btn-danger" (click)="cancel()">Abbrechen</button>
                        <button class="btn btn-success ml-2" (click)="login()">Anmelden</button>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>