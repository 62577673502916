import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Preislisten } from '../api/preislisten';
import { BackendService } from '../backend.service';
import { RealTimeService } from '../real-time.service';

@Component({
  selector: 'app-aus',
  templateUrl: './aus.component.html',
  styleUrls: ['./aus.component.css']
})
export class AusComponent implements OnInit, OnDestroy {

  public preislisten: Preislisten[];
  public selectedListe: number;
  public view = 'start';
  
  public loginUsername : string;
  public loginPassword : string;

  private subAusweis: Subscription;

  constructor(private rs: RealTimeService, private http: HttpClient, private router: Router, private backend: BackendService) { }

  ngOnDestroy(): void {
    this.subAusweis.unsubscribe();
  }

  ngOnInit(): void {
    this.subAusweis = this.rs.onMessage.subscribe(data => {
      if (data.action === 'ausweis_read') {
        /* Ausweisdaten anfragen, Session mit User starten */
        console.log(data);
        this.http.post('/api/session/login', {
          ausweis : data.ausweis,
          preisliste : this.selectedListe
        }).subscribe((response: any) => {
          if (response.result === true) {
            this.backend.sperrenErlaubt = true;
            this.backend.startKasse(response.user, this.selectedListe, response.ausweis, response.essen, response.essen_preis, response.vorname + ' ' + response.nachname);
            this.rs.send({ receiver: this.rs.posDisplay, controller : 'pos', pos : this.rs.deviceId,name: this.backend.activeUserName, action : 'standby'});
            this.router.navigate(['pos', 'user-select']);
          } else {
            this.rs.send({ receiver: this.rs.posDisplay, controller : 'pos', pos : this.rs.deviceId, action : 'off'});
            this.view = 'start';
          }
        }, (err) => {
          this.rs.send({ receiver: this.rs.posDisplay, controller : 'pos', pos : this.rs.deviceId, action : 'off'});
          this.view = 'start';
        });
      }
    });


    this.http.get('/api/preislisten').subscribe((data: Preislisten[]) => {
      this.preislisten = data;
      for (const p of data) {
        if (p.preferred) {
          this.selectedListe = p.id;
        }
      }
    });

  }

  public login() {
    this.http.post('/api/session/login-password', {
      username : this.loginUsername,
      password : this.loginPassword,
      preisliste : this.selectedListe
    }).subscribe((response: any) => {
      this.loginPassword = '';
      this.loginUsername = '';
      if (response.result === true) {
        this.backend.sperrenErlaubt = false;
        this.backend.startKasse(response.user, this.selectedListe, response.ausweis, response.essen, response.essen_preis, response.vorname + ' ' + response.nachname);
        this.rs.send({ receiver: this.rs.posDisplay, controller : 'pos', pos : this.rs.deviceId,name: this.backend.activeUserName, action : 'standby'});
        this.router.navigate(['pos', 'user-select']);
      } else {
        this.rs.send({ receiver: this.rs.posDisplay, controller : 'pos', pos : this.rs.deviceId, action : 'off'});
        this.view = 'start';
        alert(response.error.text);
      }
    }, (err) => {
      this.rs.send({ receiver: this.rs.posDisplay, controller : 'pos', pos : this.rs.deviceId, action : 'off'});
      this.view = 'start';
    });
  }

  public start() {
    this.rs.send({ receiver: this.rs.posDisplay, controller : 'pos', pos : this.rs.deviceId, action : 'start'});
    this.view = 'wait';
  }

  public cancel() {
    this.rs.send({ receiver: this.rs.posDisplay, controller : 'pos', pos : this.rs.deviceId, action : 'off'});
    this.view = 'start';
  }
}
