import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RealTimeService {

  private ws: WebSocket;
  public deviceId;
  public posDisplay;
  private stayConnected = true;

  public onMessage: Subject<any> = new Subject<any>();
  public connected: boolean = false;

  constructor() {

  }

  public close() {
    this.stayConnected = false;
  }

  private reconnect() {

    console.log('Connect to Websocketserver with DeviceID: ' + this.deviceId);
    this.deviceId = this.deviceId;
    this.ws = new WebSocket('wss://intranet.feuerwehr-maintal.org/socket');
   
    this.ws.onopen = () => {
      console.log('WebSocket connected');
      this.send({ loginKey : this.deviceId });
      this.connected = true;
    };

    this.ws.onmessage = (data) => {

      const msg = JSON.parse(data.data);

      if(msg.receiver === this.deviceId) {
        this.onMessage.next(msg);
      }
      this.connected = true;
    };

    this.ws.onclose = (e) => {
      console.log('Connection closed!');
      this.connected = false;
      if(this.stayConnected) {
        setTimeout(() => {
          console.log('Reconnect');
          this.reconnect();
        }, 1000);
      }
    };

    this.ws.onerror = (e) => {
      console.log('Connection closed due error!');
      this.connected = false;
    };
  }

  public connect(deviceId) {

    if (!deviceId) {
      return;
    }

    this.deviceId = deviceId;
    this.reconnect();
  }

  send(data: any) {
    if (!this.ws) {
      return;
    }

    console.log('Send WS', data);
    this.ws.send(JSON.stringify(data));
  }
}
