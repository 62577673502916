<ng-template #barBezahlen let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Barzahlung</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Zu zahlender Betrag: {{summe | number: '1.2'}} €</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Abbruch')">Abbrechen</button>
      <button type="button" class="btn btn-success" (click)="modal.close('Save click')">Bezahlt</button>
    </div>
</ng-template>

<ng-template #aufladenModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Kontoaufladung Bar</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <h3>Bitte Betrag eingeben</h3>
        <input type="number" value="0" [(ngModel)]="aufladeBetrag">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Abbruch')">Abbrechen</button>
      <button type="button" class="btn btn-success" (click)="modal.close('Save click')">Aufladen</button>
    </div>
</ng-template>

<ng-template #intranetPay let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Intranet Pay</h4>
    </div>
    <div class="modal-body">
        <p *ngIf="!paymentFinished">Der Betrag in Höhe von {{summe | number: '1.2'}} € kann nun über das Intranet bezahlt werden.</p>
        <p *ngIf="!paymentFinished" class="text-info">Bitte warten, bis der Nutzer der Zahlung zugestimmt hat.</p>
        <p *ngIf="paymentFinished && paymentCompleted" class="text-success">Die Zahlung wurde durchgeführt!</p>
        <p *ngIf="paymentFinished && !paymentCompleted" class="text-danger">Die Zahlung wurde abgebrochen!</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" *ngIf="!paymentFinished" (click)="modal.dismiss('Abbruch')">Abbrechen</button>
      <button type="button" class="btn btn-success" *ngIf="paymentFinished && paymentCompleted" (click)="modal.close('Beendet')">Schließen</button>
      <button type="button" class="btn btn-warning" *ngIf="paymentFinished && !paymentCompleted" (click)="modal.dismiss('Abgebrochen')">Schließen</button>
    </div>
</ng-template>

<div class="row">
    <div class="col-3 m-0 pr-0">
        <div class="d-flex justify-content-between">
            <h2>{{session?.vorname}} {{session?.nachname}}</h2>
            <h3><fa-icon icon="university"></fa-icon> {{session?.konto | number : '1.2'}} €</h3>
        </div>
        <div *ngIf="session?.artikel.length === 0">
            <h3>Bitte Artikel wählen</h3>
        </div>
        <div *ngIf="session?.artikel.length > 0">
            <ul class="list-group">
                <li *ngFor="let a of session.artikel" class="list-group-item d-flex justify-content-between align-items-center">
                {{a.bezeichnung}}
                <div>
                    <span class="badge badge-primary badge-pill mr-2" style="font-size:1.2em;">{{a.menge}}</span>
                    <a class="btn btn-danger mr-2" (click)="decreaseArtikel(a)">-</a>
                    <a class="btn btn-success" (click)="increaseArtikel(a)">+</a>
                </div>              
                </li>
            </ul>     
            <div class="d-flex justify-content-between">
                <h3>Zu zahlen</h3>
                <h3>{{summe | number : '1.2'}} €</h3>
            </div>
            <button class="btn btn-success mr-1" (click)="open(barBezahlen)"><fa-icon icon="money-bill-alt"></fa-icon> Bar zahlen</button>       
            <button class="btn btn-warning mr-1" (click)="openIntranetPay(intranetPay)"><fa-icon icon="mobile-alt"></fa-icon> Intranet Pay</button>
        </div>        
        <div *ngIf="aufladenErlaubt">
            <button class="btn btn-primary mt-1" (click)="aufladen(aufladenModal)"><fa-icon icon="money-bill-alt"></fa-icon> Aufladen</button>
        </div>
        
        
    </div>
    <div class="col-8 m-0 p-0">
        <div class="d-flex flex-wrap">
            <div class="col-2 p-1" *ngIf="isSubfolder" (click)="openRootFolder()">
                <div class="goup">
                    <div class="center">Zurück</div>
                </div>
            </div>       

            <div *ngFor="let f of activeFolder?.folder" class="col-2  p-1 ">
                <div class="artikel" (click)="openSubfolder(f)">                  
                    <div class="img-wrapper">
                        <img *ngIf="f.image !== null" [src]="'assets/' + f.image">
                    </div>                    
                    <div class="artikelname">{{f.bezeichnung}}</div>
                </div>                         
            </div>

            <div *ngFor="let a of activeFolder?.artikel" class="col-2  p-1 ">             
                    <div class="artikel" (click)="addArtikel(a)">
                        <div class="img-wrapper">
                            <img *ngIf="a.image !== null" [src]="'assets/' + a.image">
                        </div>
                        <div class="price badge badge-primary">{{a.preis | number: '1.2'}} €</div>
                        <div class="artikelname">{{a.bezeichnung}}</div>
                    </div>                             
            </div>
        </div>
    </div>
</div>