import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const provKey = localStorage.getItem('pos_prov_key');

    const update = { url: `https://kasse.feuerwehr-maintal.org${req.url}`, setHeaders: undefined };

    if (provKey) {
      update.setHeaders = { Devicekey: provKey };
    }

    const apiReq = req.clone(update);

    return next.handle(apiReq);
  }
}
