<div class="row">
    <div class="col-6">
        <ul ngbNav #nav="ngbNav" [(activeId)]="typ" class="nav-pills">
            <li [ngbNavItem]="1">
              <a ngbNavLink>Anwesend</a>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Einsatzabteilung</a>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>Ehren- und Altersabteilung</a>
            </li>
            <li [ngbNavItem]="4">
                <a ngbNavLink>Jugend</a>
            </li>
            <li [ngbNavItem]="5">
                <a ngbNavLink>Verein</a>
            </li>    
        </ul>
    </div>
    <div class="col-5">
        <ul ngbNav #nav="ngbNav" [(activeId)]="stadtteil" class="nav-pills">
            <li [ngbNavItem]="2">
              <a ngbNavLink>Bischofsheim</a>
            </li>
            <li [ngbNavItem]="1">
              <a ngbNavLink>Dörnigheim</a>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>Hochstadt</a>
            </li>
            <li [ngbNavItem]="4">
                <a ngbNavLink>Wachenbuchen</a>
            </li> 
          </ul>
    </div>
    <!--<div class="col-1">
        <button class="btn btn-primary">Neuer Gast</button>
    </div>-->
</div>


<div class="row">
    <div *ngFor="let user of userList" class="col-2 p-1">
      <div class="gast" (click)="openPos(user.id)">                  
          <div class="img-wrapper">
              <img [src]="'https://intranet.feuerwehr-maintal.org/index.php?avatar=' + user.id">
          </div>                    
          <div class="price badge" [class.badge-primary]="user.konto >= 0" [class.badge-danger]="user.konto < 0"><fa-icon icon="university"></fa-icon> {{user.konto | number : '1.2'}} €</div>
          <div class="summe badge badge-success" *ngIf="user.summe"><fa-icon icon="money-bill-alt"></fa-icon> {{user.summe | number : '1.2'}} €</div>
          <div class="gastname" *ngIf="user.spitzname">{{user.spitzname}}</div>
          <div class="gastname" *ngIf="!user.spitzname">{{user.vorname + ' ' + user.nachname }}</div>
      </div>                         
    </div>
    <h1 *ngIf="userList.length === 0" style="text-align: center; width:100%;">Keine Nutzer - Bitte Auswahl verändern</h1>
</div>