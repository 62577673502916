import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from '../backend.service';
import { RealTimeService } from '../real-time.service';

@Component({
  selector: 'app-pos-frame',
  templateUrl: './pos-frame.component.html',
  styleUrls: ['./pos-frame.component.css']
})
export class PosFrameComponent implements OnInit {

  constructor(private router: Router, public backend: BackendService, public realtime: RealTimeService) { }

  ngOnInit(): void {

  }

  public lock() {
    this.router.navigate(['locked']);
  }

}
