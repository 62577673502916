<div class="container">
    <h2>Vor dem Thekendienst</h2>
    <div class="custom-control custom-checkbox" *ngFor="let p of checkliste.vor">
        <input type="checkbox" class="custom-control-input" [id]="'check-point-' + p.id" [(ngModel)]="p.erledigt" (ngModelChange)="updateCheckliste()">
        <label class="custom-control-label" [for]="'check-point-' + p.id">{{p.bezeichnung}}</label>
    </div>
    <h2>Während dem Thekendienst</h2>
    <div class="custom-control custom-checkbox" *ngFor="let p of checkliste.waehrend">
        <input type="checkbox" class="custom-control-input" [id]="'check-point-' + p.id" [(ngModel)]="p.erledigt" (ngModelChange)="updateCheckliste()">
        <label class="custom-control-label" [for]="'check-point-' + p.id">{{p.bezeichnung}}</label>
    </div>
    <h2>Nach dem Thekendienst</h2>
    <div class="custom-control custom-checkbox" *ngFor="let p of checkliste.nach">
        <input type="checkbox" class="custom-control-input" [id]="'check-point-' + p.id" [(ngModel)]="p.erledigt" (ngModelChange)="updateCheckliste()">
        <label class="custom-control-label" [for]="'check-point-' + p.id">{{p.bezeichnung}}</label>
    </div>
</div>
