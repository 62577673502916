import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserSelectComponent } from './user-select/user-select.component';
import { PosComponent } from './pos/pos.component';
import { AusComponent } from './aus/aus.component';
import { LockedComponent } from './locked/locked.component';
import { PosFrameComponent } from './pos-frame/pos-frame.component';
import { CheckListeComponent } from './check-liste/check-liste.component';
import { AbmeldenComponent } from './abmelden/abmelden.component';
import { ProvisioningComponent } from './provisioning/provisioning.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APIInterceptor } from './api/api-interceptor';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCoffee, faMobileAlt, faMoneyBillAlt, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { BelegComponent } from './beleg/beleg.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    UserSelectComponent,
    PosComponent,
    AusComponent,
    LockedComponent,
    PosFrameComponent,
    CheckListeComponent,
    AbmeldenComponent,
    ProvisioningComponent,
    BelegComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    FontAwesomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: APIInterceptor,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faCoffee);
    library.addIcons(faUniversity);
    library.addIcons(faMoneyBillAlt);
    library.addIcons(faMobileAlt);
  }
}
