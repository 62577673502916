import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserKonto } from '../api/UserKonto';
import { BackendService } from '../backend.service';
import { RealTimeService } from '../real-time.service';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css']
})
export class UserSelectComponent implements OnInit {
  typLocal = 1;
  stadtteilLocal = 1;

  userList: Array<any> = [];

  get typ(): number { return this.typLocal; }
  set typ(t: number) {

    this.typLocal = t;

    this.loadPeople();
  }

  get stadtteil(): number { return this.stadtteilLocal; }
  set stadtteil(t: number) {    
    this.stadtteilLocal = t;
    this.loadPeople();          
  }

  constructor(private http: HttpClient, private router: Router, private rs: RealTimeService, private backend: BackendService) { }

  ngOnInit(): void {
    this.stadtteil = this.backend.kasseInfo.stadtteil;
    this.rs.send({ receiver: this.rs.posDisplay, controller : 'pos', pos : this.rs.deviceId,
    name: this.backend.activeUserName,
     action : 'standby'});
    this.loadPeople();
  }

  public openPos(userId) {
    this.backend.setUserPos(userId);
    this.router.navigate(['pos', 'pos']);
  }

  loadPeople() {
    console.log('load people', 'Typ', this.typ, 'Stadtteil', this.stadtteil);
    if (this.typ > 1) {
      /* Alle vom Backend holen */
      const listKey = this.typ + '-' + this.stadtteil;

      if (this.backend.userLists.has(listKey)) {
        this.showPeople();
      } else {
        this.backend.userLists.set(listKey, new Map<number, UserKonto>());
      }

      this.http.post('/api/user/list', { usertyp: this.typ - 2, wache : this.stadtteil }).subscribe((data: UserKonto[]) => {

        const list = this.backend.userLists.get(listKey);

        for (const konto of data) {
          if (list.has(konto.id)) {
            const entry = list.get(konto.id);
            entry.konto = konto.konto;
          } else {
            list.set(konto.id, konto);
          }
        }

        this.showPeople();
      });
    } else {
      /* Lokal anwesende anzeigen */
      this.userList = this.backend.getSessions();
    }
  }

  showPeople() {
    const listKey = this.typ + '-' + this.stadtteil;

    const userList = this.backend.userLists.get(listKey);
    const resultList = [];

    for (const [userId, user] of userList) {
      const sess = this.backend.activeSessions.get(userId);
      if (sess) {
        user.summe = sess.summe;
      } else {
        user.summe = null;
      }
      resultList.push(user);
    }
    this.userList = resultList;
  }

}
