<div class="container">
<h1>Kasse abmelden</h1>
<ul class="list-group">
    <li class="list-group-item d-flex justify-content-between align-items-center"
    [class.list-group-item-success]="thekendienstplanErledigt" 
    [class.list-group-item-danger]="!thekendienstplanErledigt">
      Thekendienstplan erledigt
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-center" 
    [class.list-group-item-success]="kontenAktiv" 
    [class.list-group-item-danger]="!kontenAktiv">
      Alle Gäste abkassiert
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-center"
    [class.list-group-item-success]="schubladen" 
    [class.list-group-item-danger]="!schubladen">
      Schubladen geschlossen
    </li>
  </ul>
  <div class="d-flex justify-content-center mt-2">
    <button class="btn btn-primary" (click)="abmelden()" [disabled]="!thekendienstplanErledigt || !kontenAktiv || !schubladen">Kasse abmelden</button>
  </div>
</div>