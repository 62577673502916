import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AbmeldenComponent } from './abmelden/abmelden.component';
import { AusComponent } from './aus/aus.component';
import { BelegComponent } from './beleg/beleg.component';
import { CheckListeComponent } from './check-liste/check-liste.component';
import { LockedComponent } from './locked/locked.component';
import { PosFrameComponent } from './pos-frame/pos-frame.component';
import { PosComponent } from './pos/pos.component';
import { ProvisioningComponent } from './provisioning/provisioning.component';
import { UserSelectComponent } from './user-select/user-select.component';


const routes: Routes = [
  { path: '', component: AusComponent },
  { path: 'locked', component: LockedComponent },
  { path: 'prov', component: ProvisioningComponent },
  { path : 'pos',
    component: PosFrameComponent,
    children: [
      { path: 'user-select', component: UserSelectComponent },
      { path: 'pos', component: PosComponent },
      { path: 'check', component: CheckListeComponent },
      { path: 'logout', component: AbmeldenComponent },
      { path: 'beleg', component: BelegComponent },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
