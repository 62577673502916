import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from '../backend.service';

@Component({
  selector: 'app-beleg',
  templateUrl: './beleg.component.html',
  styleUrls: ['./beleg.component.css']
})
export class BelegComponent implements OnInit {

  constraints = {
    video: {
        facingMode: 'environment',
        width: { ideal: 4096 },
        height: { ideal: 2160 }
    }
  };

  videoWidth = 0;
  videoHeight = 0;
  private activeStream: any;

  public photoTaken = false;

  public grund: string = null;
  public betrag: number = null;
  public user: number;

  public users: any[];

  @ViewChild('video', { static: true }) videoElement: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  constructor(private renderer: Renderer2, private http: HttpClient, private backend: BackendService, private router: Router) {}

  ngOnInit(): void {
    this.startCamera();
    this.user = this.backend.state.user;

    this.http.get('/api/user/all').subscribe((result: any[]) => {
      this.users = result;
    });
  }

  startCamera() {
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      navigator.mediaDevices
        .getUserMedia(this.constraints)
        .then(this.attachVideo.bind(this))
        .catch(this.handleError);
    } else {
        alert('Sorry, camera not available.');
    }
  }

  handleError(error) {
    console.log('Error: ', error);
  }

  attachVideo(stream) {
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    this.activeStream = stream;
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
        this.videoHeight = this.videoElement.nativeElement.videoHeight;
        this.videoWidth = this.videoElement.nativeElement.videoWidth;
    });
  }

  capture() {
    this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
    this.photoTaken = true;
    this.stopCapture();
  }

  stopCapture() {
    this.activeStream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  public save() {
    const data = this.canvas.nativeElement.toDataURL('image/jpeg');
    this.http.post('/api/beleg/save', {
      image: data,
      user: this.backend.state.user,
      moneyReceiver: this.user,
      betrag: this.betrag,
      grund: this.grund
    }).subscribe((result: any) => {
      if (result.result === true) {
        alert('Das Geld wurde dir gutgeschrieben. Bitte Schreibe die Belegnummer --> ' + result.belegid + ' <-- auf den Beleg und lege ihn in die Kasse!');
        this.router.navigate(['/pos/user-select']);
      } else {
        alert('Fehler beim Speichern des Belegs!');
      }
    }, (Err) => {
      alert('Fehler beim Speichern des Belegs!');
    });
  }

}
