import { HttpClient } from '@angular/common/http';
import { Route } from '@angular/compiler/src/core';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Checklist } from './api/checkliste';
import { KasseInfo } from './api/KasseInfo';
import { KasseState } from './api/KasseState';
import { PreislisteFolder } from './api/PreislisteFolder';
import { UserKonto } from './api/UserKonto';
import { Session } from './models/session';
import { RealTimeService } from './real-time.service';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  private displayDeviceId: string;
  private kasseProvKey: string;
  public activeSessions: Map<number, Session>;
  public activeSession: Session;
  private activeUser: number;
  public activeUserName: string;
  private activePreisliste: number;
  public checkliste: Checklist;
  public aufladenErlaubt: boolean;

  public sperrenErlaubt : boolean = true;
  
  public kasseInfo: KasseInfo = null;

  public checklisteOffen: number;
  public preisliste: PreislisteFolder;

  public state: KasseState = new KasseState();

  public userLists: Map<string, Map<number, UserKonto>> = new Map<string, Map<number, UserKonto>>();

  constructor(private router: Router, private http: HttpClient, private rs: RealTimeService) {
    const activeSessionsString = localStorage.getItem('active_sessions');
    const lastPreisliste = localStorage.getItem('preisliste');
    const jsonCheckliste = localStorage.getItem('checkliste');
    const jsonKasseInfo = localStorage.getItem('kasseinfo');

    const jsonState = localStorage.getItem('state');

    if (jsonState !== null) {
      this.state = JSON.parse(jsonState);
    }

    if (jsonCheckliste !== null) {
      this.checkliste = JSON.parse(jsonCheckliste);
      this.updateCheckliste();
    }

    if (lastPreisliste !== null) {
      this.preisliste = JSON.parse(lastPreisliste);
    }

    if (jsonKasseInfo !== null) {    
      this.kasseInfo = JSON.parse(jsonKasseInfo);      
    }

    if (activeSessionsString !== null) {
      this.activeSessions = new Map(JSON.parse(activeSessionsString));
    } else {
      this.activeSessions = new Map<number, Session>();
    }

  }

  public abgemeldet() {
    localStorage.removeItem('preisliste');
    localStorage.removeItem('active_sessions');
    localStorage.removeItem('checkliste');
    localStorage.removeItem('state');
    this.activeSessions = new Map<number, Session>();
    this.activePreisliste = 0;
    this.activeUser = 0;
  }

  public updateCheckliste() {
    if (!this.checkliste) {
      this.checklisteOffen = 0;
      return;
    }
    let offen = 0;
    for (const p of this.checkliste.nach) {
      if (!p.erledigt) {
        offen++;
      }
    }
    for (const p of this.checkliste.vor) {
      if (!p.erledigt) {
        offen++;
      }
    }
    for (const p of this.checkliste.waehrend) {
      if (!p.erledigt) {
        offen++;
      }
    }
    localStorage.setItem('checkliste', JSON.stringify(this.checkliste));
    this.checklisteOffen = offen;
  }

  public getSessions() {
    return [...this.activeSessions.values()];
  }

  public startKasse(userId, preislisteId, ausweis, essen, essenPreis, benutzerName) {
    this.activePreisliste = preislisteId;
    this.activeUser = userId;
    this.activeUserName = benutzerName;

    this.state.ausweis = ausweis;
    this.state.user = userId;
    this.state.essen = essen;
    this.state.preis = essenPreis;

    localStorage.setItem('state', JSON.stringify(this.state));

    this.http.get('/api/preislisten/' + this.activePreisliste).subscribe((data: PreislisteFolder) => {
      this.preisliste = data;
      if (!this.state.essen) {
        this.preisliste.artikel.push({ id: -1, bezeichnung: 'Essen', preis: 1.5, x: 0, y: 0, image: null });
      } else {
        this.preisliste.artikel.push({ id: -1, bezeichnung: this.state.essen, preis: this.state.preis, x: 0, y: 0, image: null });
      }
      localStorage.setItem('preisliste', JSON.stringify(this.preisliste));
    });

    this.http.get('/api/checkliste/').subscribe((data: Checklist) => {
      this.checkliste = data;
      this.updateCheckliste();
    });
  }

  public activate() {
    this.kasseProvKey = localStorage.getItem('pos_prov_key');
    this.updateKassenInfoAfterActivation();
  }

  private updateKassenInfoAfterActivation() {
    this.http.get('/api/kasse/info').subscribe((data: KasseInfo) => {

      this.kasseInfo = data;
      localStorage.setItem('kasseinfo', JSON.stringify(data));

      this.aufladenErlaubt = data.aufladen;
      
      localStorage.setItem('display_device', data.display);
      this.displayDeviceId = data.display;
      this.rs.posDisplay = data.display;
      this.rs.connect('kasse-' + this.kasseProvKey);

      if (data.active) {
        this.startKasse(data.session.user,
          data.session.preisliste,
          data.session.ausweis,
          data.session.essen,
          data.session.essenPreis,
          data.session.vorname + ' ' + data.session.nachname);
        this.router.navigate(['/pos/user-select']);
      }

    });
  }

  public saveSessions() {

    /* Remove empty sessions */
    for (const [key, value] of this.activeSessions) {
      if(value.artikel.length === 0) {
        this.activeSessions.delete(key);
      }
    }


    localStorage.setItem('active_sessions', JSON.stringify([...this.activeSessions]));
  }

  public userAufladen(userId: number, betrag: number) {
    return this.http.post('/api/user/' + userId, {
      action: 'aufladen',
      betrag: betrag
    });
  }

  public setUserPos(id) {
    let sess = this.activeSessions.get(id);
    if (!sess) {
      sess = new Session();
      sess.id = id;
      this.activeSessions.set(id, sess);
    }

    this.http.get('/api/user/' + sess.id).subscribe((data: UserKonto) => {
      sess.nachname = data.nachname;
      sess.vorname = data.vorname;
      sess.konto = data.konto;
      sess.spitzname = data.spitzname;
    });

    this.activeSession = sess;

  }

}
