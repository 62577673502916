import { HttpClient } from '@angular/common/http';
import { Component, DoCheck, KeyValueDiffer, KeyValueDiffers, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PreislisteArtikel, PreislisteFolder } from '../api/PreislisteFolder';
import { BackendService } from '../backend.service';
import { Session, SessionArtikel } from '../models/session';
import { RealTimeService } from '../real-time.service';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.css']
})
export class PosComponent implements OnInit, DoCheck, OnDestroy {

  public session: Session;
  private sessionDiffer: KeyValueDiffer<string, any>;
  public preisliste: PreislisteFolder;
  public activeFolder: PreislisteFolder;
  public isSubfolder = false;
  public summe = 0;
  private paymentLocked = false;
  private waitForPaymentTransactionId: number;
  private waitForPaymentTransaction: any;
  public paymentFinished = false;
  public paymentCompleted = false;
  public aufladenErlaubt:boolean = false;
  public aufladeBetrag: number = 0;

  private sub: Subscription;

  constructor(private rs: RealTimeService,
              private backend: BackendService,
              private activeRoute: ActivatedRoute,
              private differs: KeyValueDiffers,
              private http: HttpClient,
              private router: Router,
              private modalService: NgbModal) { }

  ngOnDestroy(): void {
    if(this.sub) {
      this.sub.unsubscribe();
    }    
  }

  ngOnInit(): void {
    this.session = this.backend.activeSession;
    this.aufladenErlaubt = this.backend.aufladenErlaubt;
    if (!this.session) {
      console.log('No active session!');
      setTimeout(() => {
        this.router.navigate(['/pos/user-select']);
      }, 500);      
      return;
    }
    
    this.sessionDiffer = this.differs.find(this.session).create();
    this.calcSum();
    this.preisliste = this.backend.preisliste;
    this.activeFolder = this.preisliste;

    this.sub = this.rs.onMessage.subscribe(data => {
      if (data.action === 'ausweis_read') {
        /* Ausweis wurde gelesen => Bezahlen über Konto des Ausweisinhabers */
        console.log('Zahlen bitte!', this.session);

        if (!this.paymentLocked) {
          this.paymentLocked = true;
          this.http.post('/api/transaction',
          {
            payment: 'ausweis',
            ausweis: data.ausweis,
            session: this.session,
            kassierer: this.backend.state.user
          }).subscribe((result: any) => {
            if (result.result === true) {
              this.clearSession();
              this.paymentLocked = false;
              this.router.navigate(['/pos/user-select']);
            } else {
              alert('Während der Transaktion ist folgender Fehler aufgetreten: ' + result.error);
            }
            this.paymentLocked = false;
          }, (err) => {
            alert('Während der Transaktion ist ein unerwarteter Fehler aufgetreten.');
            this.paymentLocked = false;
          });
        }
      }
    });
  }

  checkIntranetPay() {
    this.http.get('/api/transaction/' + this.waitForPaymentTransactionId).subscribe((result: any) => {
      if (result.solved === true) {
        clearInterval(this.waitForPaymentTransaction);
        this.paymentCompleted = result.ack;
        this.paymentFinished = result.solved;

        if (this.paymentCompleted) {
          /* Zahlung erfolgt */
          this.clearSession();
          this.paymentLocked = false;
        } else {
          this.paymentLocked = false;
        }
      }
    });
  }

  openIntranetPay(content) {
    this.paymentLocked = true;
    this.paymentCompleted = false;
    this.paymentFinished = false;

    this.http.post('/api/transaction',
    {
      payment: 'intranetPay',
      session: this.session,
      kassierer: this.backend.state.user
    }).subscribe((result: any) => {
      if (result.result === true) {
        this.waitForPaymentTransactionId = result.transactionId;
        this.waitForPaymentTransaction = setInterval(() => {
          this.checkIntranetPay();
        }, 500);
      }
    }, (err) => {
      alert('Während der Transaktion ist ein unerwarteter Fehler aufgetreten.');
      this.paymentLocked = false;
    });

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.router.navigate(['/pos/user-select']);
    }, (dismissed) => {
      this.paymentLocked = false;
    });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      /* Bar bezahlt */
      this.paymentLocked = true;
      this.http.post('/api/transaction',
      {
        payment: 'bar',
        session: this.session,
        kassierer: this.backend.state.user
      }).subscribe((result: any) => {
        if (result.result === true) {
          this.clearSession();
          this.paymentLocked = false;
          this.router.navigate(['/pos/user-select']);
        } else {
          alert('Während der Transaktion ist folgender Fehler aufgetreten: ' + result.error);
        }
        this.paymentLocked = false;
      }, (err) => {
        alert('Während der Transaktion ist ein unerwarteter Fehler aufgetreten.');
        this.paymentLocked = false;
      });
    });
  }

  public aufladen(content) {
    this.paymentLocked = true;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      /* Aufladen */
      this.backend.userAufladen(this.session.id, this.aufladeBetrag).subscribe(x => {
        this.session.konto += this.aufladeBetrag;        
        this.aufladeBetrag = 0;
        this.updateDisplay();
        this.paymentLocked = false;
      }, err => {
        alert('Fehler beim Aufladen des Betrags!');
        this.paymentLocked = false;
      })
    });
  }


  private clearSession() {
    this.session.artikel = [];
    this.calcSum();
    this.backend.saveSessions();
  }

  ngDoCheck(): void {
    if(this.session) {
      const changes = this.sessionDiffer.diff(this.session);
      if (changes) {
        this.updateDisplay();
      }
    }
  }

  public openRootFolder() {
    this.activeFolder = this.preisliste;
    this.isSubfolder = false;
  }

  public openSubfolder(folder) {
    this.activeFolder = folder;
    this.isSubfolder = true;
  }

  private calcSum() {
    let result = 0;
    for (const a of this.session.artikel) {
      result += a.menge * a.einzelpreis;
    }
    this.summe = result;
    this.session.summe = result;
    this.updateDisplay();
  }

  public increaseArtikel(artikel: SessionArtikel) {
    artikel.menge++;
    this.calcSum();
    this.backend.saveSessions();
  }

  public decreaseArtikel(artikel: SessionArtikel) {
    artikel.menge--;
    if (artikel.menge <= 0) {
      this.session.artikel.splice(this.session.artikel.indexOf(artikel), 1);
    }
    this.calcSum();
    this.backend.saveSessions();
  }

  public addArtikel(artikel: PreislisteArtikel) {
    let found = false;

    if (!this.session.artikel) {
      this.session.artikel = [];
    }

    for (const a of this.session.artikel) {
      if (a.id === artikel.id) {
        found = true;
        a.menge++;
      }
    }

    if (!found) {
      this.session.artikel.push({ bezeichnung: artikel.bezeichnung, einzelpreis: artikel.preis, id: artikel.id, menge: 1 });
    }
    this.calcSum();
    this.backend.saveSessions();
  }

  private updateDisplay() {
    const kontoData = {
      receiver: this.rs.posDisplay,
      controller : 'pos',
      pos : this.rs.deviceId,
      action : 'konto',
      konto: {
        vorname : this.session.vorname,
        nachname : this.session.nachname,
        kontostand : this.session.konto,
        summe: this.summe,
        positionen : []
      }
    };

    for (const a of this.session.artikel) {
      kontoData.konto.positionen.push({
        menge : a.menge,
        bezeichnung: a.bezeichnung,
        preis: a.einzelpreis,
        preisGesamt: a.einzelpreis * a.menge
      });
    }

    this.rs.send(kontoData);
  }

}
