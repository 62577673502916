import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from '../backend.service';
import { RealTimeService } from '../real-time.service';

@Component({
  selector: 'app-abmelden',
  templateUrl: './abmelden.component.html',
  styleUrls: ['./abmelden.component.css']
})
export class AbmeldenComponent implements OnInit {

  public kontenAktiv: boolean;
  public thekendienstplanErledigt: boolean;
  public schubladen: boolean;

  constructor(private backend: BackendService, private http: HttpClient,
              private rs: RealTimeService,
              private router: Router) { }

  ngOnInit(): void {
    this.schubladen = true;
    this.kontenAktiv = true;
    this.thekendienstplanErledigt = (this.backend.checklisteOffen === 0);
    const sessions = this.backend.getSessions();
    for (const s of sessions) {
      if (s.artikel.length > 0) {
        this.kontenAktiv = false;
      }
    }
  }

  public abmelden() {
    if (this.kontenAktiv && this.thekendienstplanErledigt) {
      this.http.post('/api/session/logout', { logout: true }).subscribe((response: any) => {
        this.rs.send({ receiver: this.rs.posDisplay, controller : 'pos', pos : this.rs.deviceId, action : 'off'});
        this.backend.abgemeldet();
        this.router.navigate(['']);
      });
    }
  }

}
