<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" [routerLink]="['/pos/user-select']">Kasse</a>
    <a class="btn btn-outline-primary my-2 my-sm-0 mr-2" [routerLink]="['/pos/user-select']">
      Gästeliste
    </a>

      <ul class="navbar-nav mr-auto">
        <button class="btn btn-outline-primary my-2 my-sm-0 mr-2" *ngIf="backend.kasseInfo.rundengong !== null">
            Rundengong
        </button>
        <button class="btn btn-outline-success my-2 my-sm-0 mr-2"  [routerLink]="['/pos/beleg']">
          Beleg erfassen
      </button>
      </ul>

      <form class="form-inline my-2 my-lg-0">
        <div class="btn btn-outline-danger my-2 my-sm-0 mr-2" *ngIf="!realtime.connected">Keine Verbindung</div>
        <a class="btn btn-outline-primary my-2 my-sm-0 mr-2" [routerLink]="['/pos/check']">
            Thekendienstplan <span class="badge" [class.badge-danger]="backend.checklisteOffen > 0" [class.badge-success]="backend.checklisteOffen === 0">{{backend.checklisteOffen}}</span>
        </a>
        <button class="btn btn-outline-info my-2 my-sm-0 mr-2" [hidden]="!backend.sperrenErlaubt" type="submit" (click)="lock()">
          Kasse Sperren
        </button>
        <a class="btn btn-outline-danger my-2 my-sm-0" type="submit" [routerLink]="['/pos/logout']">
          Abmelden
        </a>
      </form>
      
  </nav>
  <router-outlet></router-outlet>