import { Component, OnInit } from '@angular/core';
import { Checklist } from '../api/checkliste';
import { BackendService } from '../backend.service';

@Component({
  selector: 'app-check-liste',
  templateUrl: './check-liste.component.html',
  styleUrls: ['./check-liste.component.css']
})
export class CheckListeComponent implements OnInit {

  public checkliste: Checklist;
  constructor(private backend: BackendService) { }

  ngOnInit(): void {
    this.checkliste = this.backend.checkliste;
  }

  public updateCheckliste() {
    this.backend.updateCheckliste();
  }
}
