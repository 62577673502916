import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Kassen } from '../api/kassen';
import { BackendService } from '../backend.service';

@Component({
  selector: 'app-provisioning',
  templateUrl: './provisioning.component.html',
  styleUrls: ['./provisioning.component.css']
})
export class ProvisioningComponent implements OnInit {

  public selectKassen: Kassen[];

  constructor(private http: HttpClient, private router: Router, private backend: BackendService) { }

  ngOnInit(): void {
    this.http.get('/api/kassen').subscribe((data: Kassen[]) => {
      this.selectKassen = data;
    });
  }

  public prov(kasse: Kassen) {
    localStorage.setItem('pos_prov_key', kasse.provId);
    this.backend.activate();
    this.router.navigate(['']);
  }
}
